import calculateSumOfAllContractDurationsInYears from "./calculateSumOfAllContractDurationsInYears";
import { calculateTotalContractDurationOfRecommendations } from "./recommendationSetup";
import type { Contract } from "~/src/generated-sources/public";
import type { AlternativeImpactContract } from "~/types/shared/impact-calculator";

/**
 * Calculate impact of contracts for lives in Madagaskar and Cambodia
 * according to years and amount of contracts (no difference between type power/gas)
 *
 * @param recommendationCount The amount of recommendations of the customer.
 * @param contracts The contracts to calculate the investment for.
 * @returns The amount of lives improved in Madagaskar and Cambodia.
 */
export default function (
  recommendationCount: number,
  contracts: Contract[] | AlternativeImpactContract[],
) {
  const yearlyInvestment = 20;
  const requiredInvestmentForImprovedLife = 50;

  const customerYears = calculateSumOfAllContractDurationsInYears(contracts);
  const recomYears = calculateTotalContractDurationOfRecommendations(recommendationCount);

  const totalYears = customerYears + recomYears;
  return Math.floor((totalYears * yearlyInvestment) / requiredInvestmentForImprovedLife);
}
